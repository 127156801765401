import Nav from '@layout/nav/Nav';
import { Profile } from '@modules/auth/model';
import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { Stack, Text, Title } from '@mantine/core';
import { AlertTriangle } from 'tabler-icons-react';
import { useStyles } from '@styles/hooks';

interface LayoutProps {
  profile: Profile;
}

const Layout: FC<LayoutProps> = ({ profile }) => {
  const { classes } = useStyles(styles, Layout, undefined);

  return (
    <>
      <Stack className={classes.lock} p="md" align="center">
        <AlertTriangle size={50} />
        <Title>On dirait que vous êtes sur un appareil mobile.</Title>
        <Text>
          Pour une meilleure expérience utilisateur, nous vous invitons à vous connecter depuis un ordinateur et à
          activer le mode « plein écran » de votre navigateur.
        </Text>
      </Stack>

      <div className={classes.container}>
        <Nav profile={profile} />

        <div className={classes.content}>
          <Suspense fallback={<DebouncedLineLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Layout;
