import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={{
        'html, body': {
          height: '100%',
          overflow: 'hidden',
          background: '#f5f5f5',
        },
        '#root': {
          height: '100%',
          overflow: 'hidden',
        },
      }}
    />
  );
};

export { GlobalStyles };
