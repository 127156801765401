import { createStyles } from '@mantine/core';

const styles = createStyles({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '270px 1fr',
    height: '100%',

    '@media screen and (max-width: 1080px)': {
      display: 'none',
    },
  },

  content: {
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
  },

  lock: {
    display: 'none',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',

    '@media screen and (max-width: 1080px)': {
      display: 'flex',
    },
  },
});

export default styles;
