import React, { FC, useEffect, useState } from 'react';
import { createStyles, keyframes } from '@mantine/core';
import { useStyles } from '@styles/hooks';

const loadingAnimation = keyframes({
  from: { left: '-200px', width: '30%' },
  '50%': { width: '30%' },
  '70%': { width: '70%' },
  '80%': { left: '50%' },
  '95%': { left: '120%' },
  to: { left: '100%' },
});

const styles = createStyles(theme => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 2,
    width: '100%',
    overflow: 'hidden',
    zIndex: 1,

    '&:before': {
      display: 'block',
      position: 'absolute',
      content: "''",
      left: -200,
      width: 200,
      height: 2,
      backgroundColor: theme.colors[theme.primaryColor][5],
      animation: `${loadingAnimation} 2s linear infinite`,
    },
  },
}));

interface DebouncedLineLoaderProps {
  timeout?: number;
  className?: string;
}

const LineLoader: FC<Pick<DebouncedLineLoaderProps, 'className'>> = ({ className }) => {
  const { classes, cx } = useStyles(styles, LineLoader, undefined);

  return <div className={cx(classes.loader, className)} />;
};

export const DebouncedLineLoader: FC<DebouncedLineLoaderProps> = ({ timeout, className }) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShow(true);
    }, timeout ?? 500);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [timeout]);

  return show ? <LineLoader className={className} /> : null;
};

export default LineLoader;
